<template>
  <div class="home">
    <div class="bannerImg">
      <div class="bannerTi">
        <div class="bannerTitle">素养植根</div>
        <div class="bannerText">在错误中改进 在改进中进阶 在进阶中蜕变</div>
      </div>
      <div @click="useNow" class="useNow">立即使用</div>
      <!-- <div @click="lookNow" class="lookNow">
         查看定价
        </div> -->
      <!-- <a href="./syzg.pdf"></a> -->
    </div>
    <div class="selectRole">
      <transition name="slide-fade">
        <div class="trans">
          <div
            v-trigger
            :id="'nav' + index"
            :class="currentIndex === index ? 'activeLi' : ''"
            v-for="(item, index) in selectRoleList"
            :key="index"
            @click="roleSelect(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
      </transition>
    </div>
    <div class="content">
      <transition name="slide-fade">
        <router-view></router-view>
      </transition>
    </div>
    <div class="bottom">
      <div class="bot_title">为什么选择素养植根</div>
      <ul class="whyUl">
        <li class="whyLi">
          <div class="whyLeft">
            <img class="img" src="../../assets/img/20.png" alt="" />
          </div>
          <div class="whyRight">
            <div class="name">统编版高中语文教材</div>
            <div class="cont">素养植根融媒体教材以高中统编版语文教材为基础</div>
          </div>
        </li>
        <li class="whyLi">
          <div class="whyLeft">
            <img class="img" src="../../assets/img/21.png" alt="" />
          </div>
          <div class="whyRight">
            <div class="name">研发团队专业、强大</div>
            <div class="cont">
              集成语文领域专家、学者和一线语文教师的群体智慧
            </div>
          </div>
        </li>
        <li class="whyLi">
          <div class="whyLeft">
            <img class="img" src="../../assets/img/22.png" alt="" />
          </div>
          <div class="whyRight">
            <div class="name">专题学习设计理念</div>
            <div class="cont">基于专题学习理念组织内容、精心设计</div>
          </div>
        </li>
        <li class="whyLi">
          <div class="whyLeft">
            <img class="img" src="../../assets/img/23.png" alt="" />
          </div>
          <div class="whyRight">
            <div class="name">资源表征丰富多样</div>
            <div class="cont">
              运用多媒体网络技术、移动互联网和人工智能技术，融合丰富多样的资源表征
            </div>
          </div>
        </li>
        <li class="whyLi">
          <div class="whyLeft">
            <img class="img" src="../../assets/img/24.png" alt="" />
          </div>
          <div class="whyRight">
            <div class="name">多终端产品覆盖</div>
            <div class="cont">PC端、手机端、pad端等多终端应用互联互通</div>
          </div>
        </li>
      </ul>
    </div>
    <!-- <div class="register">
      <div class="button" @click="goRegister">立即注册<img class="img" src="../../assets/img/teacher-right@2x.png" alt=""></div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import HeadTop from "@/components/HeadTop.vue";
export default {
  name: "product",
  data() {
    return {
      currentIndex: 0,
      selectRoleList: [
        { name: "教师", key: "teacher", id: "1" },
        { name: "学生", key: "student", id: "2" },
        { name: "学校", key: "school", id: "3" }
      ]
    };
  },
  methods: {
    roleSelect(item, index) {
      this.currentIndex = index;
      this.$router.push({ name: item.key });
    },
    useNow() {
      const user_info = localStorage.getItem("user_info");
      if (user_info && user_info.roleids == 5) {
        window.location.replace(this.$teaUrl + "page/qt/tea/index.html");
      } else if (user_info && user_info.roleids != 5) {
        window.location.replace(this.$stuUrl + "page/qt/stu/index.html");
      } else {
        this.$router.push({ name: "login" });
      }
    },
    lookNow() {
      // this.$router.push({name: 'pricePDF'})
      const a = document.createElement("a");
      a.setAttribute("href", "./syzg.pdf");
      a.setAttribute("target", "_blank");
      window.open(a);
    },
    goRegister() {
      this.$router.push({ name: "register" });
    }
  },
  components: {
    HeadTop
  },
  directives: {
    trigger: {
      inserted(el, binging) {
        el.id == "nav0" ? el.click() : null;
      }
    }
  }
};
</script>
<style lang="less" scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.home {
  background: #f6f6f6;
  position: relative;
  .bannerImg {
    width: 100%;
    height: 390px;
    margin: 0 auto;
    background-image: url("../../assets/img/40.png");
    background-size: 100% 100%;
    position: relative;
    .useNow {
      cursor: pointer;
      width: 120px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 24%;
      z-index: 9;
      background: #db5647;
      border-radius: 30px;
      text-align: center;
      line-height: 40px;
      // text-shadow: 0px 2px 8px #DBCEBC;
      color: #fff;
      font-size: 14px;
    }
    .lookNow {
      cursor: pointer;
      width: 120px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: calc(24% + 140px);
      z-index: 9;
      border-radius: 30px;
      text-align: center;
      line-height: 40px;
      // text-shadow: 0px 2px 8px #DBCEBC;
      color: #db5647;
      border: 1px solid #db5647;
      font-size: 14px;
    }
    .bannerTi {
      position: absolute;
      top: 20%;
      left: 24%;
      .bannerTitle {
        height: 65px;
        line-height: 65px;
        font-size: 36px;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        font-weight: 500;
        color: #323232;
        line-height: 65px;
        letter-spacing: 5px;
        background: angular-gradient(180deg, #a3411e 0%, #5e1e07 100%);
        -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
      .bannerText {
        height: 32px;
        line-height: 32px;
        font-size: 18px;
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        font-weight: 400;
        color: #5e1e07;
        line-height: 33px;
        // background: linear-gradient(191deg, #F1EBE2 0%, #E6D7CD 100%);
      }
    }
  }
  .selectRole {
    width: 900px;
    height: 60px;
    background: #fff;
    margin: auto;
    position: absolute;
    top: 360px;
    left: 50%;
    margin-left: -450px;
    z-index: 9;
    cursor: pointer;
    .trans {
      width: 100%;
      display: flex;
      div {
        width: 33.33%;
        text-align: center;
        font-size: 22px;
        line-height: 60px;
        transition: all 1s;
        display: inline-block;
      }
    }
  }
}
.bottom {
  width: 1140px;
  height: 580px;
  margin: 0 auto;
  // background: #fff;
  padding: 30px;
  background: url("../../assets/img/19.png");
  background-size: 100% 100%;
  .bot_title {
    width: 60%;
    margin: auto;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #000;
    font-weight: bold;
    font-size: 28px;
  }
  .whyUl {
    width: 60%;
    height: 500px;
    margin: auto;
    .whyLi {
      display: flex;
      height: 80px;
      margin-top: 12px;
      .whyLeft {
        width: 150px;
        height: 100%;
        text-align: center;
        .img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-top: 8px;
        }
      }
      .whyRight {
        width: 550px;
        height: 100%;
        color: #000;
        .name {
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          font-weight: 500;
        }
        .cont {
          height: 26px;
          line-height: 26px;
          font-size: 16px;
        }
      }
    }
  }
}
.register {
  height: 80px;
  width: 1140px;
  margin: auto;
  text-align: center;
  .button {
    margin: auto;
    margin-top: 30px;
    width: 300px;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(122deg, #ff8d46 0%, #fa5542 100%);
    box-shadow: 0px 2px 20px 0px #dbcebc;
    border-radius: 50px;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    .img {
      width: 20px;
      height: 20px;
      margin-left: 10px;
      margin-top: -3px;
    }
  }
}
.activeLi {
  background: #db5647;
  color: #fff;
  transition: all 1s;
  display: inline-block;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  // transform: translateX(30px);
  opacity: 0;
}
</style>
